<template>
  <div class="contracts-page mt-4 ml-4">
    <VCol cols="12 d-flex align-center justify-space-between">
      <span class="page_title">
        Контракты
        <span class="input-title text-top">({{ contracts.total }})</span>
      </span>
      <UniversalButton text="Создать контракт" @click="routeToContract" />
    </VCol>
    <VCol cols="12">
      <ContractsTable
        :allow-deletion="contracts.canDelete"
        :contracts="contracts.results"
        @filter="updateSearchParams"
      />
    </VCol>
    <VCol class="d-flex justify-end" cols="12">
      <UniversalButton
        class="mr-3"
        color="green"
        :disabled="contracts.currentPage <= 1"
        icon=""
        large
        text="Предыдущие"
        @click="getPreviousPage"
      />
      <UniversalButton
        color="green"
        :disabled="contracts.currentPage >= contracts.lastPage"
        icon=""
        large
        text="Следующие"
        @click="getNextPage"
      />
    </VCol>
  </div>
</template>

<script>
import UniversalButton from '@/components/UniversalButton.vue';
import ContractsTable from '@/components/ContractsTable.vue';
import { fetchContracts } from '@/api/api';

export default {
  components: {
    UniversalButton,
    ContractsTable,
  },
  data() {
    return {
      contracts: {},
      searchParams: {
        page: 1,
      },
    };
  },
  mounted() {
    this.filterContracts();
  },
  methods: {
    routeToContract() {
      this.$router.push({
        name: 'contracts-add',
      });
    },
    updateSearchParams(searchParams) {
      Object.assign(this.searchParams, searchParams, { page: 1 });
      this.filterContracts();
    },
    getPreviousPage() {
      this.searchParams.page -= 1;
    },
    getNextPage() {
      this.searchParams.page += 1;
    },
    filterContracts() {
      fetchContracts(this.searchParams).then((response) => {
        this.contracts = response;
      });
    },
  },
  watch: {
    searchParams: {
      deep: true,
      handler() {
        this.filterContracts();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/page.scss";

.text-top {
  vertical-align: text-top;
}
</style>
