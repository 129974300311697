<template>
  <div>
    <VDialog
      v-model="dialog"
      class="d-flex"
      max-width="600"
    >
      <VCard class="justify-space-around d-flex flex-wrap">
        <VDatePicker
          v-model="selectedDates"
          color="green lighten-1"
          locale="ru"
          no-title
          range
        />
        <VDatePicker
          v-model="selectedDates"
          color="green lighten-1"
          locale="ru"
          no-title
          range
        >
          <VCol cols="12 d-flex justify-end">
            <UniversalButton
              class="mr-2"
              color="black"
              icon="close"
              text="Отменить"
              @click="() => {
                selectedDates = [];
                dialog = false;
                filter.dateStart = '';
                filter.dateEnd = '';
              }"
            />
            <UniversalButton
              class="mr-2"
              icon="check"
              text="Выбрать"
              @click="dialog = false; searchBy()"
            />
          </VCol>
        </VDatePicker>
      </VCard>
    </VDialog>
    <DeletionPopUp
      v-if="showDeletionPopup"
      :title="deletionTitle"
      @confirm="deleteContract"
    />
    <VSimpleTable class="elevation-2">
      <thead>
        <tr class="titles-thead">
          <th class="text-center id-column">
            <span>ID(статус)</span>
          </th>
          <th class="text-center title-column">
            <span>Название</span>
          </th>
          <th class="text-left city-column">
            <span>Город</span>
          </th>
          <th class="text-left">
            <span>Юр.лицо</span>
          </th>
          <th class="text-left">
            <span>БИН</span>
          </th>
          <th class="text-left">
            <span>Почта</span>
          </th>
          <th class="text-left date-column">
            <span>Дата договора</span>
          </th>
          <th class="text-left manager-column">
            <span>Менеджер</span>
          </th>
          <th></th>
        </tr>
        <tr class="search-thead">
          <th>
            <VTextField
              v-model="filter.id"
              background-color="#5B5B5B"
              clearable
              dark
              dense
              hide-details
              outlined
              placeholder="ID"
              solo
              @change="searchBy()"
            />
          </th>
          <th>
            <VTextField
              v-model="filter.title"
              background-color="#5B5B5B"
              clearable
              dark
              dense
              hide-details
              outlined
              placeholder="Название"
              solo
              @change="searchBy()"
            />
          </th>
          <th>
            <VSelect
              v-model="filter.city"
              background-color="#5B5B5B"
              clearable
              dark
              dense
              hide-details
              item-text="title"
              item-value="title"
              :items="cities"
              label="Все"
              outlined
              prepend-inner-icon="mdi-magnify"
              solo
              @change="searchBy()"
            />
          </th>
          <th>
            <VTextField
              v-model="filter.organizationName"
              background-color="#5B5B5B"
              clearable
              dark
              dense
              hide-details
              outlined
              placeholder="Юридическое лицо"
              solo
              @change="searchBy()"
            />
          </th>
          <th>
            <VTextField
              v-model="filter.bin"
              background-color="#5B5B5B"
              clearable
              dark
              dense
              hide-details
              outlined
              placeholder="Введите БИН"
              solo
              @change="searchBy()"
            />
          </th>
          <th>
            <VTextField
              v-model="filter.partnerEmail"
              background-color="#5B5B5B"
              clearable
              dark
              dense
              hide-details
              outlined
              placeholder="Введите email"
              solo
              @change="searchBy()"
            />
          </th>
          <th>
            <UniversalButton
              class="date-picker grey--text"
              color="#5B5B5B"
              icon="calendar-month"
              :text="selectedDates.length ? 'Даты выбраны' : 'Выберите дату'"
              @click="pickDate()"
            />
          </th>
          <th>
            <VTextField
              v-model="filter.manager"
              background-color="#5B5B5B"
              clearable
              dark
              dense
              hide-details
              outlined
              solo
              @change="searchBy()"
            />
          </th>
          <th>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(contract, index) in contracts"
          :key="contract.id"
          style="height: 60px"
        >
          <td>
            <div class="d-flex align-center">
              <div
                class="status-dot mr-3"
                :class="contract.approved ? 'green lighten-3' : 'red lighten-3'"
              />
              <span>
                {{ contract.id }}
              </span>
            </div>
          </td>
          <td>
            <span>
              {{ contract.title }}
            </span>
          </td>
          <td>
            <span>
              {{ contract.city }}
            </span>
          </td>
          <td class="text-center">
            <span>
              {{ contract.organizationName }}
            </span>
          </td>
          <td>
            <span>
              {{ contract.bin }}
            </span>
          </td>
          <td>
            <span>
              {{ contract.email }}
            </span>
          </td>
          <td>
            <span>
              {{ contract.date }}
            </span>
          </td>
          <td>
            <span>
              {{ contract.manager }}
            </span>
          </td>
          <td class="text-center">
            <div class="d-flex space-around buttons-wrapper">
              <VBtn
                class="mr-auto"
                color="#EB5757"
                :disabled="!allowDeletion"
                elevation="0"
                fab
                max-height="35"
                max-width="35"
                @click="prepareDeletion(contract, index)"
              >
                <VImg
                  contain
                  height="20px"
                  :src="trashIcon"
                  width="20px"
                />
              </VBtn>
              <RouterLink
                class="mr-auto"
                :to="{
                  name: 'contracts-edit',
                  params: { id: contract.id }
                }"
              >
                <VBtn
                  color="#B7BED0"
                  elevation="0"
                  fab
                  max-height="35"
                  max-width="35"
                >
                  <VIcon color="white">
                    mdi-chevron-right
                  </VIcon>
                </VBtn>
              </RouterLink>
            </div>
          </td>
        </tr>
      </tbody>
    </VSimpleTable>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { deleteContract } from '@/api/api';
import trashIcon from '@/assets/icons/trash.svg';
import UniversalButton from '@/components/UniversalButton.vue';
import DeletionPopUp from '@/components/DeletionPopUp.vue';

export default {
  components: {
    UniversalButton,
    DeletionPopUp,
  },
  props: {
    contracts: {
      type: Array,
      default: () => [],
    },
    allowDeletion: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['filter'],
  data() {
    return {
      dialog: false,
      selectedDates: [],
      filter: {
        city: '',
        id: null,
        title: '',
        manager: '',
        bin: '',
        organizationName: '',
        dateStart: '',
        dateEnd: '',
        partnerEmail: '',
      },
      deletionTitle: '',
      showDeletionPopup: false,
      contractToDelete: null,
      trashIcon,
    };
  },
  methods: {
    ...mapActions(['getCities']),
    prepareDeletion(contract) {
      this.contractToDelete = contract;
      this.deletionTitle = contract.organizationName;
      this.showDeletionPopup = true;
    },
    deleteContract(userChoice) {
      const events = {
        delete: () => {
          deleteContract(this.contractToDelete.id).then(() => {
            this.contractToDelete = null;
            this.searchBy();
          }).catch(() => {
            this.showDeletionPopup = true;
          });
          this.showDeletionPopup = false;
        },
        cancel: () => {
          this.showDeletionPopup = false;
        },
      };
      events[userChoice]();
    },
    searchBy() {
      const deserializedFilter = {
        city: this.filter.city || undefined,
        id: this.filter.id || undefined,
        manager: this.filter.manager || undefined,
        bin: this.filter.bin || undefined,
        organization_name: this.filter.organizationName || undefined,
        title: this.filter.title || undefined,
        date_start: this.filter.dateStart || undefined,
        date_end: this.filter.dateEnd || undefined,
        partner_email: this.filter.partnerEmail || undefined,
      };
      this.$emit('filter', deserializedFilter);
    },
    pickDate() {
      this.dialog = true;
    },
  },
  created() {
    this.getCities();
  },
  computed: {
    ...mapGetters(['cities']),
  },
  watch: {
    selectedDates: {
      deep: true,
      handler() {
        if (this.selectedDates.length > 1) {
          const sortedRange = [...this.selectedDates].sort();
          this.filter.dateStart = sortedRange[0];
          this.filter.dateEnd = sortedRange[1];
        }
      },
    },
  },
};
</script>

<style scoped lang='scss'>
@import 'src/assets/scss/mixin.scss';

.id-column {
  width: 120px;
}
.city-column {
  width: 250px;
}
.date-column,
.date-picker {
  width: 150px;
}

.short-description-column {
  width: 200px;
}

.manager-column {
  width: 180px;
}

.status-dot {
  height: 9px;
  width: 9px;
  border-radius: 50%;
}

.buttons-wrapper {
  width: 80px;
}

.titles-thead th {
  color: $default_dark_gray_color !important;
  background-color: white;
  span {
    font-size: 12px;
    font-weight: normal;
  }
}
.search-thead th {
  color: white !important;
  background-color: $default_gray_color;
}
.title-column {
  width: 250px;
}
td {
  border-bottom: none !important;
  span {
    font-size: 14px;
  }
}
a {
  text-decoration: none;
}
</style>
